<template>
  <div>
    <div class="hover-wrapper" @click="$refs.file.click()">
      <slot class="on-hover"></slot>
      <div class="hover-text">
        <span
          >{{
            $vuetify.lang.t(
              "$vuetify.main.psychologistDashboard.settings.imageInput"
            )
          }}<v-icon color="primary">mdi-upload</v-icon></span
        >
      </div>
    </div>
    <input
      :disabled="handleDisableInputByBufferPhoto"
      ref="file"
      type="file"
      hidden
      accept="image/*"
      v-on:change="handleChangeAvatar"
    />
  </div>
</template>

<script>
export default {
  name: "ImageInput",
  data() {
    return {
      icon: "mdi-upload",
      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  props: {
    handleChangeAvatar: {
      type: Function,
      required: false,
      default: () => function () {},
    },
    handleDisableInputByBufferPhoto: {
      type: Boolean,
      required: false,
      default: () => function () {},
    },
    getAvatarElement: {
      type: Function,
      required: false,
      default: () => function () {},
    },
  },
  mounted() {
    this.getAvatarElement();
  },
  methods: {
    // onInputChange(e) {
    //   this.handleChangeAvatar(e);
    // },
  },
};
</script>

<style lang="sass" scoped>
.hover-wrapper
  position: relative
  width: 250px
  height: 250px
  border-radius: 50%
  text-align: center
  &:hover
    .hover-text
      opacity: 1
      cursor: pointer
      background-color: rgba(0,0,0, 0.6)


.hover-text
  top: 0
  left: 0
  position: absolute
  display: inline-flex
  width: 250px
  height: 250px
  opacity: 0
  align-items: center
  justify-content: center
  transition: all .4s ease-in-out
  border-radius: 50%
  &>span
    font-size: 20px
    font-weight: bold
    color: white
</style>
